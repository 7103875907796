<template>
  <div class="w-full flex justify-center">
    <div class="flex-auto w-full max-w-lg p-10 pb-20">
      <ErrorResponseHandle :errors="errors" />
      <SuccessResponseHandle :successes="successes" />
      <!-- <div class="border rounded my-4">
        <h1 class="text-center font-semibold text-red-500 p-4">
          Camera Test
        </h1>
        <div
          class="p-4 flex justify-center"
        >
          <router-link
            :to="{name:'Camera'}" 
            class="px-2 py-1 text-white bg-blue-400 rounded-md"
          > 
            Camera 
          </router-link>
        </div>
      </div> -->
      <div class="flex-auto w-full">
        <router-link
          :to="{ name: 'MemberIdentification' }"
          class="inline-block border rounded-lg mr-4 text-center text-gray-600 bg-transparent hover:text-white hover:bg-gray-600 transition-all duration-500 ease-in-out"
        >
          <div class="flex justify-center p-3 rounded-t-lg items-center">
            <svg
              class="w-14 h-14"
              fill="currentColor"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 32 32"
            >
              <title>Add Member</title>
              <path
                d="M12 23c0-4.726 2.996-8.765 7.189-10.319 0.509-1.142 0.811-2.411 0.811-3.681 0-4.971 0-9-6-9s-6 4.029-6 9c0 3.096 1.797 6.191 4 7.432v1.649c-6.784 0.555-12 3.888-12 7.918h12.416c-0.271-0.954-0.416-1.96-0.416-3z"
              />
              <path
                d="M23 14c-4.971 0-9 4.029-9 9s4.029 9 9 9c4.971 0 9-4.029 9-9s-4.029-9-9-9zM28 24h-4v4h-2v-4h-4v-2h4v-4h2v4h4v2z"
              />
            </svg>
          </div>
          <h1
            class="bg-gray-600 text-white rounded-b-lg p-2 px-3 font-semibold text-sm"
          >
            Add Member
          </h1>
        </router-link>
        <router-link
          :to="{ name: 'GuestDetails' }"
          class="inline-block text-center border rounded-lg text-gray-600 bg-transparent mr-4 hover:text-white hover:bg-gray-600 transition-all duration-500 ease-in-out"
        >
          <div class="flex justify-center items-center rounded-t-lg p-3">
            <svg
              class="w-14 h-14"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              stroke="none"
              viewBox="0 0 24 24"
            >
              <title>Add Coupon</title>
              <path
                d="M21,5h-1h-2H6H4H3C2.447,5,2,5.447,2,6v1v1.938V10h0.893c0.996,0,1.92,0.681,2.08,1.664C5.177,12.917,4.215,14,3,14H2 v1.062V17v1c0,0.553,0.447,1,1,1h1h2h12h2h1c0.553,0,1-0.447,1-1v-1v-1.938V14c0,0-0.447,0-1,0c-1.215,0-2.177-1.083-1.973-2.336 c0.16-0.983,1.084-1.664,2.08-1.664H22V8.938V7V6C22,5.447,21.553,5,21,5z M11,17H9v-2h2V17z M11,13H9v-2h2V13z M11,9H9V7h2V9z"
              />
            </svg>
          </div>
          <h1
            class="bg-gray-600 text-white rounded-b-lg p-2 px-3 font-semibold text-sm"
          >
            Add Coupons
          </h1>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import ErrorResponseHandle from "./errors/ErrorResponseHandle.vue";
import SuccessResponseHandle from "./errors/SuccessResponseHandle.vue";

export default {
  name: "AddMemberOrGiftCard",
  components: { SuccessResponseHandle, ErrorResponseHandle },
  data() {
    return {
      successes: [],
      errors: [],
      // branchId:'',
      // localBranchId:'',
    };
  },
  mounted() {
    // try {
    //   this.$store.dispatch("LOG_LIGHTSPEED_RECEIPT");
    // } catch (e) {
    //   // document.getElementById("error-response").innerHTML = e.message;
    // }
    // if(localStorage.getItem('branchId')){
    //   this.branchId=this.localBranchId=localStorage.getItem('branchId');
    // }
    this.$store.commit("updateIsGuest", false);
    this.$store.dispatch("STOP_LOADING");
  },
  methods: {
    // setBranchId(){
    //   localStorage.setItem('branchId',this.branchId);
    //   this.localBranchId=localStorage.getItem('branchId',this.branchId);
    // }
  },
};
</script>
