<template>
  <button
    class="shadow-md hover:shadow-lg mr-4 h-16 w-32 font-medium py-1 bg-gradient-to-br text-white font-semibold border cursor-pointer rounded text-center self-center"
    :type="type"
    :class="cssClass"
    :disabled="disabled"
  >
    <h1 class="flex justify-center items-center">
      <slot />
      <span class="px-2 text-sm md:text-normal">
        {{ getText }}
      </span>
    </h1>
  </button>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: "Button",
    },
    type: {
      type: String,
      default: "button",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    btnType: {
      type: String,
      default: "info",
    },
    size: {
      type: String,
      default: "medium",
    },
  },
  data() {
    return {
      color: {
        info: "blue",
        warning: "yellow",
        danger: "red",
        success: "green",
        gray: "gray",
      },
      tailwindTextSize: {
        small: "text-sm",
        meduim: "text-base",
        large: "text-lg",
        xLarge: "text-xl",
      },
    };
  },
  computed: {
    cssClass() {
      let classes = `hover:font-bold ${this.getTextSizeClass} from-${this.getColor}-200 to-${this.getColor}-500`;
      classes += this.disabled ? " cursor-not-allowed opacity-50" : "";
      return classes;
    },
    getColor() {
      return this.color[this.getType];
    },
    getType() {
      return this.btnType.toLowerCase();
    },
    getText() {
      return this.text[0].toUpperCase() + this.text.slice(1);
    },
    getTextSizeClass() {
      return this.tailwindTextSize[this.size.toLowerCase()];
    },
  },
};
</script>
