import { createApp } from "vue";

require("dotenv").config();

import App from "./App.vue";
import Store from "./store";
import Router from "./routes";
import Mixin from "./mixin";
import axios from "axios";
import { vfmPlugin } from "vue-final-modal";
import "./assets/css/index.css";
import * as Sentry from "@sentry/vue";

const app = createApp(App);
Sentry.init({
  app,
  dsn:
    "https://4145bab395c347f69dfeca498c12cbec@o4505069174652928.ingest.sentry.io/4505069177143296",
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(Router),
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

app.mixin(Mixin);
app
  .use(Store)
  .use(Router)
  .use(vfmPlugin)
  .mount("#app");
app.config.globalProperties.$axios = axios;
app.config.globalProperties.$filters = {
  formatCurrencyUSD(amount) {
    // convert cent into dollar
    amount = amount / 100;

    if (typeof amount !== "number" || isNaN(amount)) {
      console.error("Number Required");
      return;
    }

    // if (amount < 0) {
    //     return "-" + "$ " + Math.abs(amount).toFixed(2);
    // }
    return Store.getters.currency + " " + amount.toFixed(2);

    // return amount.toLocaleString('en-US', {
    //     style: 'currency',
    //     currency: 'EUR'
    // });

    // return new Intl.NumberFormat('en-US', {
    //     style: 'currency',
    //     currency: 'USD',
    //     currencyDisplay: 'narrowSymbol'
    // }).format(amount / 100);
  },
  convertFromCent(amount) {
    amount = amount / 100;
    if (typeof amount !== "number" || isNaN(amount)) {
      console.error("Number Required");
      return;
    }
    return amount.toFixed(2);
  },
};
