<template>
  <div class="w-full flex justify-center">
    <div class="flex-auto w-full max-w-lg p-10 pb-20">
      <ErrorResponseHandle :errors="errors" />
    </div>
  </div>
</template>
<script >
  import Helper from '../helper';
import ErrorResponseHandle from './errors/ErrorResponseHandle.vue';
export default {
    components: { ErrorResponseHandle },
    data() {
        return {
            errors:[]
        };
    },
    mounted() {
        try {
            this.$store.commit("updateAccountId", this.$route.query.accountID);
            this.$store.commit("updateSaleId", this.$route.query.saleID);
            this.$store.commit("updateEmployeeId", this.$route.query.employeeID);
            this.$store.commit("updateCustomerId", this.$route.query.customerID);
            this.$store.commit("updateCallBackUrlRSeries", this.$route.query.returnURL);
            this.$store.dispatch("GET_SALE_DETAILS").then(response => {
                this.$store.commit("updateLightSpeedSuccessHandler", response.data);
                let sale = response.data.sale;
                this.$store.commit("updateVatInclusive", sale.vatInclusive || true);
                let purchaseReceiptInComoFormat = Helper.convertRSeriesIntoComoObject(sale);
                this.$store.commit("updatePurchaseDetails", purchaseReceiptInComoFormat);
                /** Based on LightSpeed Data try to find Member in como */
                let identificationForm = Helper.convertIntoIdentificationFormData(response.data.customer);
                this.$store.commit("updateIdentificationFormData", identificationForm);
                // if como@como redirect to addMemberOrGiftCardPage
                if (!identificationForm.email && !identificationForm.phoneNumber) {
                    return this.$router.push({ name: "AddMemberOrGiftCard" });
                }
                return this.$router.push({ name: "MemberIdentification" });
            })
            .catch(error => {
                this.errors = [];
                console.error(error.response);
                if (error.response) {
                  for (let i in error.response.data) {
                    if (typeof error.response.data[i] == "object") {
                      for (let j in error.response.data[i]) {
                        this.errors.push(error.response.data[i][j]);
                      }
                    }else{
                      this.errors.push(error.response.data[i]);
                    }
                  }
                  this.isLoadingPaymentSubmit=false;
                  // }
                } else if (error.request) {
                  this.errors.push(error.message);
                  this.isLoadingPaymentSubmit=false;
                } else {
                  this.errors.push(error.message);
                  this.isLoadingPaymentSubmit=false;
                }
              });
        }
        catch (error) {
          this.errors = [];
          console.error(error.response);
          if (error.response) {
            for (let i in error.response.data) {
              if (typeof error.response.data[i] == "object") {
                for (let j in error.response.data[i]) {
                  this.errors.push(error.response.data[i][j]);
                }
              }else{
                this.errors.push(error.response.data[i]);
              }
            }
            this.isLoadingPaymentSubmit=false;
            // }
          } else if (error.request) {
            this.errors.push(error.message);
            this.isLoadingPaymentSubmit=false;
          } else {
            this.errors.push(error.message);
            this.isLoadingPaymentSubmit=false;
          }
        }
    },
}
</script>

<style scoped>
</style>