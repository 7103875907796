const Helper = (function() {
  /**
   * @description Path related operations
   *
   * @access public
   *
   * @method join: function (arg1, arg2)
   *
   * @method joinApiPath: function (arg1)
   */
  let path = {
    /**
     * @description this method will join two urls one of them must a baseurl
     *
     * @access public
     *
     * @param {string} arg1
     * @param {string} arg2
     *
     * @returns {URL} url
     */
    join: function(arg1, arg2) {
      let newUrl;
      try {
        newUrl = new URL(arg1, arg2);
      } catch (error) {
        newUrl = new URL(arg2, arg1);
      }
      return newUrl;
    },
    /**
     * @description this will return url string using base api Path and given pathName
     *
     * @access public
     *
     * @param {string} pathName
     *
     * @returns string
     */
    joinApiPath: function(pathName) {
      return this.join(process.env.VUE_APP_API_BASE_PATH, pathName).href;
    },
  };

  function convertIntoComoObject(lightSpeedReceipt, openTime = null) {
    const items = convertLSReceiptItemsToComoItems(
      lightSpeedReceipt.receiptItems,
      lightSpeedReceipt.vatInclusive
    );
    const totalAmount = items.reduce((acc, item) => (acc += item.netAmount), 0);
    let now = new Date();
    return {
      // transaction Time is not mention in LightSpeed Data
      openTime: openTime
        ? openTime
        : new Date(
            Date.UTC(
              now.getUTCFullYear(),
              now.getUTCMonth(),
              now.getUTCDate(),
              now.getUTCHours(),
              now.getUTCMinutes()
            )
          ).toISOString(),
      transactionId: lightSpeedReceipt.uuid,
      totalAmount: Math.round(totalAmount),
      items: items,
    };
  }

  function convertLSReceiptItemsToComoItems(receiptItem, vatInclusive) {
    if (!Array.isArray(receiptItem)) {
      return [];
    }
    let items = [];
    receiptItem.forEach((item) => {
      items.push({
        quantity: item.amount,
        lineId: item.id,
        code: item.plu,
        name: item.productName,

        // Not confirmed
        departmentCode: item.priceTypeId,
        departmentName: "Department Name",

        // Como takes money in cent
        grossAmount: Math.round(
          (!vatInclusive ? item.totalPriceWithoutVat : item.totalPrice) * 100
        ),
        netAmount: Math.round(
          (!vatInclusive ? item.totalPriceWithoutVat : item.totalPrice) * 100
        ),
      });
    });
    return items;
  }

  function convertIntoIdentificationFormData(customer) {
    if (customer == null) {
      customer = [];
    }
    return {
      phoneNumber: customer["telephone"] ? customer["telephone"] : "",
      email: customer["email"] ? customer["email"] : "",
      appClientId: "",
      customIdentifier: "",
      memberId: customer["memberId"] || "",
    };
  }

  /** K Series Methods */

  function convertKSeriesIntoComoObject(lightSpeedReceipt) {
    const items = convertKSeriesLSReceiptItemsToComoItems(
      lightSpeedReceipt.transactionLines,
      lightSpeedReceipt.vatInclusive
    );
    const totalAmount = items.reduce((acc, item) => (acc += item.netAmount), 0);
    return {
      // transaction Time is not mention in LightSpeed Data
      openTime: lightSpeedReceipt.openDate,
      transactionId: lightSpeedReceipt.identifier,
      /**convert into cent */
      totalAmount: totalAmount,
      items: items,
    };
  }

  function convertKSeriesLSReceiptItemsToComoItems(
    receiptItem,
    vatInclusive = true
  ) {
    if (!Array.isArray(receiptItem)) {
      return [];
    }
    let items = [];
    receiptItem.forEach((item) => {
      let index = items.findIndex((el) => {
        return el.code === item.itemSku;
      });
      if (index >= 0) {
        items[index].quantity =
          Number(items[index].quantity) + Math.round(item.quantity / 100);
        items[index].grossAmount =
          Number(items[index].grossAmount) +
            Number(!vatInclusive ? item.totalPriceWithoutVat : item.amount) ||
          0;
        items[index].netAmount =
          Number(items[index].netAmount) +
            Number(!vatInclusive ? item.totalPriceWithoutVat : item.amount) ||
          0;
        return;
      }
      items.push({
        /** on development time quantity was getting multiplied by 100 */
        quantity: Math.round(item.quantity / 100),
        lineId: item.identifier,
        code: item.itemSku,
        name: item.text,
        departmentCode: item.accountingGroupId,
        departmentName: item.accountingGroupName,
        unitAmount: Number(item.unitAmount),
        modificationDate: item.modificationDate,

        // K-series sent money in cent
        /** on development duration no data was receiving related to totalPriceWithoutVat so
         * item.totalPriceWithoutVat may change
         * */
        grossAmount:
          Number(!vatInclusive ? item.totalPriceWithoutVat : item.amount) || 0,
        netAmount:
          Number(!vatInclusive ? item.totalPriceWithoutVat : item.amount) || 0,
      });
    });
    return items;
  }

  function convertKSeriesIntoIdentificationFormData(customer) {
    if (customer && !Object.keys(customer).length) {
      console.error("customer not found");
      return;
    }
    return {
      phoneNumber: customer.phoneNumber || "",
      email: customer.email || "",
      appClientId: customer.appClientId || "",
      customIdentifier: customer.identifier || "",
      memberId: customer.memberId || "",
    };
  }

  /** R Series Methods */

  function convertRSeriesIntoComoObject(lightSpeedReceipt) {
    console.log("lightSpeedReceipt", lightSpeedReceipt);
    const items = convertRSeriesLSReceiptItemsToComoItems(
      lightSpeedReceipt.saleLines
    );
    // const totalAmount = items.reduce((acc, item) => acc += item.netAmount, 0);
    return {
      // transaction Time is not mention in LightSpeed Data
      openTime: lightSpeedReceipt.openTime,
      transactionId: lightSpeedReceipt.transactionId,
      /**convert into cent */
      totalAmount: lightSpeedReceipt.totalAmount,
      items: items,
    };
  }

  function convertRSeriesLSReceiptItemsToComoItems(receiptItem) {
    if (!Array.isArray(receiptItem)) {
      return [];
    }
    let items = [];
    receiptItem.forEach((item) => {
      console.log("item", item);
      items.push({
        /** on development time quantity was getting multiplied by 100 */
        quantity: item.quantity,
        lineId: item.lineId,
        code: item.code,
        name: item.name,
        departmentCode: item.departmentCode,
        departmentName: item.departmentName,
        unitAmount: item.unitAmount,
        // modificationDate: item.modificationDate,
        grossAmount: item.grossAmount,
        netAmount: item.netAmount,
      });
    });
    return items;
  }

  return {
    path,
    convertIntoComoObject,
    convertIntoIdentificationFormData,
    convertKSeriesIntoComoObject,
    convertKSeriesIntoIdentificationFormData,
    convertRSeriesIntoComoObject,
  };
})();
export default Helper;
