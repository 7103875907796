<template>
  <div class="w-full flex justify-center">
    <div class="flex-auto w-full max-w-lg p-10 pb-20">
      <ErrorResponseHandle :errors="errors" />
      <div id="error-response"></div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import helper from "../helper";
import ErrorResponseHandle from "./errors/ErrorResponseHandle.vue";
import KSeriesData from "../assets/simulated-data/KSeries";
import LSeriesData from "../assets/simulated-data/LSeries";
export default {
  name: "Home",
  components: { ErrorResponseHandle },
  props: {},
  data() {
    return {
      errors: [],
    };
  },
  computed: {
    /**
     * The mapGetters helper simply maps store getters to local computed properties:
     * @see https://vuex.vuejs.org/guide/getters.html#the-mapgetters-helper
     */
    ...mapGetters([
      "vatInclusive",
      "identificationFormData",
      "member",
      "businessLocationId",
      "identifiers",
    ]),
  },
  mounted() {
    console.log(process.env);
    this.$store.dispatch("START_LOADING");
    /**
     * Update companyId from query parameter
     */
    if (this.$route.query.companyId) {
      this.$store.commit("updateCompanyId", this.$route.query.companyId);
    }

    if (this.$route.query.businessLocationId) {
      this.$store.commit(
        "updateBusinessLocationId",
        this.$route.query.businessLocationId
      );
    }

    if (this.businessLocationId) {
      const successHandlerKSeries = (response) => {
        try {
          let data = response.data;
          this.$store.commit("updateLightSpeedSuccessHandler", data);
          try {
            this.$store.dispatch("LOG_LIGHTSPEED_RECEIPT");
          } catch (e) {
            // document.getElementById("error-response").innerHTML = e.message;
          }
          this.$store.commit("updateVatInclusive", data.vatInclusive || true);
          let purchaseReceiptInComoFormat = helper.convertKSeriesIntoComoObject(
            data
          );
          this.$store.commit(
            "updatePurchaseDetails",
            purchaseReceiptInComoFormat
          );
          /** Based on LightSpeed Data try to find Member in como */
          let identificationForm = helper.convertIntoIdentificationFormData(
            data.consumer
          );
          this.$store.commit(
            "updateIdentificationFormData",
            identificationForm
          );
          //if como@como redirect to addMemberOrGiftCardPage
          if (
            !identificationForm.email ||
            identificationForm.email == "como@como.com"
          ) {
            identificationForm.email = "";
            return this.$router.push({ name: "AddMemberOrGiftCard" });
          }
          return this.$router.push({ name: "MemberIdentification" });
        } catch (e) {
          document.getElementById("error-response").innerHTML = e.message;
        }
      };
      setTimeout(() => {
        try {
          window.pos_getCurrentAccount(successHandlerKSeries);
        } catch (e) {
          this.$store.commit("enableTestDataMode");
          successHandlerKSeries({ data: KSeriesData });
        }
      }, 200);
    } else {
      const successHandlerLSeries = (successHandler) => {
        this.$store.commit("updateLightSpeedSuccessHandler", successHandler);

        try {
          this.$store.dispatch("LOG_LIGHTSPEED_RECEIPT");
        } catch (e) {
          document.getElementById("error-response").innerHTML = e.message;
        }

        this.$store.commit("updateVatInclusive", successHandler.vatInclusive);
        let purchaseReceiptInComoFormat = helper.convertIntoComoObject(
          successHandler
        );
        this.$store.commit(
          "updatePurchaseDetails",
          purchaseReceiptInComoFormat
        );

        /**
         * Based on LightSpeed Data try to find Member in como
         */
        let identificationForm = helper.convertIntoIdentificationFormData(
          successHandler.customer
        );
        this.$store.commit("updateIdentificationFormData", identificationForm);

        //if como@como redirect to addMemberOrGiftCardPage
        if (identificationForm.email == "como@como.com") {
          identificationForm.email = "";
          return this.$router.push({ name: "AddMemberOrGiftCard" });
        }
        return this.$router.push({ name: "MemberIdentification" });
      };
      const failureHandlerLSeries = (failureHandler) => {
        this.errors = failureHandler;
        console.log(failureHandler);
        this.$store.dispatch("STOP_LOADING");
      };
      try {
        window.LSLoyalty.getReceipt(
          successHandlerLSeries,
          failureHandlerLSeries
        );
      } catch (e) {
        this.$store.commit("enableTestDataMode");
        successHandlerLSeries(LSeriesData);
      }
    }
  },
};
</script>
