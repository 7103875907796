<template>
  <svg
    v-if="isLoading"
    class="animate-spin text-white"
    :class="cssClass"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
  >
    <circle
      class="opacity-25"
      cx="12"
      cy="12"
      r="10"
      stroke="currentColor"
      stroke-width="4"
    />
    <path
      class="opacity-75"
      fill="currentColor"
      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
    />
  </svg>
</template>

<script>
export default {
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: "medium",
    },
  },
  data() {
    return {
      widthHeight: {
        small: "w-3 h-3",
        medium: "w-5 h-5",
        large: "w-8 h-8",
        xLarge: "w-12 h-12",
      },
      marginLeft: {
        small: "ml-1",
        medium: "ml-2",
        large: "ml-3",
        xLarge: "ml-5",
      },
    };
  },
  computed: {
    cssClass() {
      return `${this.getWidthHeight} ${this.getRightMargin}`;
    },
    getWidthHeight() {
      return this.widthHeight[this.size];
    },
    getRightMargin() {
      return this.marginLeft[this.size];
    },
  },
};
</script>

<style>
</style>