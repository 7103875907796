<template>
  <div class="relative">
    <LoaderMain :loading="isLoading" />
    <cockpit>
      <router-view />
    </cockpit>
  </div>
</template>

<script>
import Cockpit from "./components/Cockpit.vue";
import LoaderMain from "./components/partials/LoaderMain.vue";
export default {
  name: "App",
  components: { Cockpit, LoaderMain },
  computed: {
    isLoading: {
      get() {
        return this.$store.getters.isPageLoading;
      },
      set(value) {
        return this.$store.commit("updateIsPageLoading", value);
      },
    },
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
.router-link-exact-active {
  color: blue;
  text-decoration: underline;
}
</style>
