<template>
  <!-- component -->
  <div class="bg-white w-full overflow-hidden sm:rounded-lg pb-8">
    <div class=" text-center pt-8">
      <h1 class="text-5xl font-bold text-purple-400">
        404
      </h1>
      <h1 class="text-3xl font-medium py-8">
        oops! Not Found
      </h1>
      <p class="text-xl pb-8 px-12 font-medium">
        Page Not exist
      </p>
      <div>
        <ButtonSecondary
          text="BACK"
          @click="$store.dispatch('BACK')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ButtonSecondary from "../partials/ButtonSecondary.vue";

export default {
  components: { ButtonSecondary },
  mounted() {
        this.$store.dispatch("STOP_LOADING");
    
  },
};
</script>
