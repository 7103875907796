<template>
  <div class="flex justify-center items-center" style="margin-top: 60px">
    <div
      v-if="loading"
      class="absolute rounded-xl w-full md:w-3/4 lg:w-3/5 h-full bg-blue-400 bg-opacity-60 flex justify-center items-center text-center z-50"
      style="top: 0px;"
    >
      <div>
        <svg
          class="animate-spin text-center ml-3 w-16 h-16 text-white"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            class="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            stroke-width="4"
          />
          <path
            class="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          />
        </svg>
        {{ text }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LoaderLarge",
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: "Processing...",
    },
  },
};
</script>

<style></style>
