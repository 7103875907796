<template>
  <div class="flex items-center justify-center h-full">
    <div
      id="main"
      class="bg-white rounded-xl shadow-lg w-full md:w-3/4 lg:w-3/5 pb-2 md:pb-4 lg:pb-8"
    >
      <div
        class="h-8 rounded-t-xl flex items-center justify-center font-bold text-sm text-gray-100 relative"
        style="background-color: #07a3c8"
      >
        COMO
        <small
          v-if="isTestDataMode"
          class="absolute right-0 px-2 font-bold bg-white rounded mr-4 border border-red-500 text-red-500"
          style="font-size: 10px"
        >
          SIMULATED DATA
        </small>
      </div>
      <!-- <nav v-if="companyId">
        <ul>
          <li v-if="selectedBranchId">
            <h1 class="text-lg">
              Active Branch Id :
              <span class="font-semibold">
                {{ selectedBranchId }} 
              </span>
            </h1>
          </li>
          <li>
            <router-link 
              v-if="businessLocationId&&$route.name!='SelectBranchId'"
              class="underline text-red-400"
              :to="{name:'SelectBranchId',query:{businessLocationId:businessLocationId}}"
            >
              Reset Branch
            </router-link>
            <router-link 
              v-if="companyId"
              class="underline text-red-400"
              :to="{name:'SelectBranchId',query:{companyId:companyId}}"
            >
              Reset Branch
            </router-link>
          </li>
        </ul>
      </nav> -->
      <slot />
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["isTestDataMode"]),
  },
};
</script>
<style>
#main {
  background-color: #ffffff;
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='12' viewBox='0 0 20 12' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6 12c0-.622-.095-1.221-.27-1.785A5.982 5.982 0 0 0 10 12c1.67 0 3.182-.683 4.27-1.785A5.998 5.998 0 0 0 14 12h2a4 4 0 0 1 4-4V6c-1.67 0-3.182.683-4.27 1.785C15.905 7.22 16 6.622 16 6c0-.622-.095-1.221-.27-1.785A5.982 5.982 0 0 0 20 6V4a4 4 0 0 1-4-4h-2c0 .622.095 1.221.27 1.785A5.982 5.982 0 0 0 10 0C8.33 0 6.818.683 5.73 1.785 5.905 1.22 6 .622 6 0H4a4 4 0 0 1-4 4v2c1.67 0 3.182.683 4.27 1.785A5.998 5.998 0 0 1 4 6c0-.622.095-1.221.27-1.785A5.982 5.982 0 0 1 0 6v2a4 4 0 0 1 4 4h2zm-4 0a2 2 0 0 0-2-2v2h2zm16 0a2 2 0 0 1 2-2v2h-2zM0 2a2 2 0 0 0 2-2H0v2zm20 0a2 2 0 0 1-2-2h2v2zm-10 8a4 4 0 1 0 0-8 4 4 0 0 0 0 8zm0-2a2 2 0 1 0 0-4 2 2 0 0 0 0 4z' fill='%23c3f4ff' fill-opacity='0.3' fill-rule='evenodd'/%3E%3C/svg%3E");
}
</style>
