export default {
  activeUserId: 107020,
  receiptItems: [
    {
      itemId: 1,
      parentId: "0",
      amount: 1,
      id: "1",
      prodId: 2601948,
      productName: "Burger",
      plu: "PLUTEST04",
      priceTypeId: 0,
      totalPrice: 7,
      totalPriceWithoutVat: 5.79,
    },
    {
      itemId: 2,
      parentId: "0",
      amount: 1,
      id: "2",
      prodId: 2572966,
      productName: "Test Free Product",
      plu: "PLUTEST03",
      priceTypeId: 0,
      totalPrice: 6.66,
      totalPriceWithoutVat: 4.62,
    },
  ],
  ownerUserId: 107020,
  totalDue: 13.66,
  uuid: "dce87d6870bf11ecb707def022384b89",
  vatInclusive: true,
  customer: {
    lastname: "Corthals",
    language: null,
    zip: null,
    deliveryStreetNumber: null,
    deliveryCity: null,
    firstname: "Victor",
    birthYear: 0,
    deliveryDepartment: null,
    birthDay: 0,
    deliveryCompany: null,
    street: null,
    telephone: "478010318",
    uuid: "0c354a7ec36e11eb9257be 85956bb5d6",
    streetNumber: null,
    city: null,
    deliveryStreet: null,
    birthMonth: 0,
    deliveryZip: null,
    deliveryFloor: null,
    email: "como@como.com",
  },
  total: 13.66,
  discountableReceiptItems: [
    {
      itemId: 1,
      parentId: "0",
      amount: 1,
      id: "1",
      prodId: 2601948,
      productName: "Burger",
      plu: "PLUTEST04",
      priceTypeId: 0,
      totalPrice: 7,
      totalPriceWithoutVat: 5.79,
    },
    {
      itemId: 2,
      parentId: "0",
      amount: 1,
      id: "2",
      prodId: 2572966,
      productName: "Test Free Product",
      plu: "PLUTEST03",
      priceTypeId: 0,
      totalPrice: 6,
      totalPriceWithoutVat: 4.96,
    },
  ],
};
