<template>
  <!-- component -->
  <div class="bg-white overflow-hidden sm:rounded-lg pb-8">
    <div class="text-center pt-8">
      <h1 class="text-5xl font-bold text-purple-400">
        {{ $route.query.code }}
      </h1>
      <h1 class="text-3xl font-medium py-8">
        oops! {{ $route.query.title }}
      </h1>
      <p class="text-xl pb-8 px-12 font-medium">
        {{ $route.query.message }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  mounted() {
        this.$store.dispatch("STOP_LOADING");
    
  },
};
</script>
<style>
</style>