<template>
  <div class="p-2 px-4 w-full">
    <div class="flex">
      <div class="w-1/2">
        <p class="text-left capitalize text-base">
          {{ asset.name }}
        </p>
      </div>
      <div class="w-1/2 text-right text-sm italic">
        Valid Till {{ asset.validUntil }}
      </div>
    </div>
    <div class="text-sm text-left italic pt-2">
      <span v-if="!asset.redeemable">
        Code : {{ asset.nonRedeemableCause.code }}
        <span class="px-2">|</span> Message:{{
          asset.nonRedeemableCause.message
        }}
      </span>
      <span v-else>
        {{ asset.description }}
      </span>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    asset: {
      type: Object,
      default: () => {}
    },
    active: {
      type: Boolean,
      // require:true,
      default: false
    }
  },
  data() {
    return {};
  },
  computed: {},
  methods: {}
};
</script>
