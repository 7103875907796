import {
  createRouter,
  createWebHistory
} from 'vue-router'
import EPOS from './components/EPOS.vue'
import RSeries from './components/RSeries.vue'
import Home from './components/Home.vue'
import MemberIdentification from './components/MemberIdentification.vue'
import MemberDetails from './components/MemberDetailsCard.vue'
import GuestDetails from './components/GuestDetailsCard.vue'
import MemberRegistration from './components/MemberRegistrationCard.vue'
import AssetCard from './components/AssetCard.vue'
import UseCode from './components/UseCode.vue'
import RedeemWithComo from './components/RedeemWithComo.vue'
import AddMemberOrGiftCard from './components/AddMemberOrGiftCard.vue'
import Camera from './components/Camera.vue'
// import SelectBranchId from './components/SelectBranchId.vue'
import Error from './components/errors/Error.vue'
import Error404 from './components/errors/Error404.vue'
import store from './store'

const routes = [
  {
    path: '/camera',
    name: 'Camera',
    component: Camera,
    props: true,
  },
  {
    path: '/epos',
    name: 'EPOS',
    component: EPOS,
    props: true,
  },
  {
    path: '/r-series',
    name: 'RSeries',
    component: RSeries,
    props: true,
  },
  // {
  //   path: '/select-branch',
  //   name: 'SelectBranchId',
  //   component: SelectBranchId,
  //   props: true,
  // },
  {
    path: '',
    name: 'Home',
    component: Home,
    props: true,
  },
  {
    path: '/member-identification',
    name: 'MemberIdentification',
    component: MemberIdentification,
    meta: {
      ensureAuth: false
    }
  },
  {
    path: '/member-registration',
    name: 'MemberRegistration',
    component: MemberRegistration,
    meta: {
      ensureAuth: false
    }
  },
  {
    path: '/member-details',
    name: 'MemberDetails',
    component: MemberDetails,
    meta: {
      ensureAuth: true
    }

  },
  {
    path: '/guest-details',
    name: 'GuestDetails',
    component: GuestDetails,
    meta: {
      ensureAuth: false
    }

  },
  {
    path: '/asset-card',
    name: 'AssetCard',
    component: AssetCard,
    meta: {
      ensureAuth: true
    }
  },
  {
    path: '/add-member-or-gift-card',
    name: 'AddMemberOrGiftCard',
    component: AddMemberOrGiftCard,
    meta: {
      ensureAuth: false
    }
  },
  {
    path: '/use-code',
    name: 'UseCode',
    component: UseCode,
    meta: {
      ensureAuth: true
    }

  },
  {
    path: '/redeem-with-como',
    name: 'RedeemWithComo',
    component: RedeemWithComo,
    meta: {
      ensureAuth: true
    }

  },
  {
    path: '/error',
    name: 'error',
    component: Error,
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'error404',
    component: Error404,
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})
router.beforeEach((to, from, next) => {

  // console.log(`redirecting from ${from.name} to ${to.name}`);
  if (to.query.companyId&&!store.getters.companyId) {
    store.commit('updateCompanyId',to.query.companyId);
    }
  if(to.query.businessLocationId&&!store.getters.businessLocationId){
    store.commit('updateBusinessLocationId',to.query.businessLocationId);
  }
  // if(store.getters.businessLocationId&&to.name==='SelectBranchId'){
  //     return next({
  //       name: 'Home',
  //       query: {
  //         businessLocationId: store.getters.businessLocationId,
  //       }}) 
  //     }
  if (['error',"RSeries",'EPOS','Camera'].includes(to.name)) {
    return next();
  }
    else if (!(store.getters.companyId||store.getters.businessLocationId||store.getters.accountId)) {
      return next({
        name: 'error',
        query: {
          code: 409,
          title: 'Unauthorized',
          message: 'Query Parameter companyId or businessLocationId is required'
        }
      })
    }
    // else if(store.getters.companyId&&to.name=='SelectBranchId'){
    //   next();
    // }
    // else if(store.getters.companyId&&(!store.getters.selectedBranchId)){
    //   next({
    //     name: 'SelectBranchId',
    //     query: {
    //       companyId: store.getters.companyId,
    //     }
    //   }) 
    // }
  else if (to.matched.some(record => record.meta.ensureAuth && !store.getters.isGuest)) {    
     if (store.getters.member.constructor === Object && Object.keys(store.getters.member).length === 0) {
       if (store.getters.businessLocationId) {
         next({
           name: 'Home',
           query: {
             businessLocationId: store.getters.businessLocationId,
           }
         }) 
       } else {
         next({
           name: 'Home',
           query: {
             companyId: store.getters.companyId,
           }
         })
       }
     } else {
       next();
     }
  } 
    else {
      next();
  }


})
export default router;