<template>
  <button
    class="flex justify-center w-full py-3 mt-6 font-medium text-white uppercase rounded-full shadow hover:shadow-md item-center focus:shadow-outline focus:outline-none"
    :class="cssClass"
    :type="type"
    :disabled="disabled"
  >
    <span class="flex justify-center items-center">
      <slot />
      <span class="px-2">
        {{ getText }}
      </span>
    </span>
  </button>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: "Button"
    },
    type: {
      type: String,
      default: "button"
    },
    disabled: {
      type: Boolean,
      default: false
    },
    btnType: {
      type: String,
      default: "info"
    }
  },
  data() {
    return {
      bgColor: {
        info: "blue",
        warning: "yellow",
        danger: "red",
        success: "green",
        gray: "gray"
      },
      textColor: {
        info: "white",
        warning: "black",
        danger: "white",
        success: "white",
        gray: "white"
      }
    };
  },
  computed: {
    cssClass() {
      let classes = ` bg-${this.getBgColor}-500 hover:bg-${this.getBgColor}-400 text-${this.getTextColor}-500 `;
      classes += this.disabled ? " cursor-not-allowed opacity-50" : "";
      return classes;
    },
    getBgColor() {
      return this.bgColor[this.getTyep];
    },
    getTextColor() {
      return this.textColor[this.getTyep];
    },
    getTyep() {
      return this.btnType.toLowerCase();
    },
    getText() {
      return this.text[0].toUpperCase() + this.text.slice(1);
    }
  }
};
</script>
