<template>
  <!-- component -->
  <div class="p-4">
    <form
      class="p-4 flex justify-center"
      @submit.prevent="addCode"
    >
      <input
        v-model="giftCode"
        class="rounded-l-lg p-3 border-t mr-0 border-b border-l text-gray-800 border-gray-200 bg-white"
        placeholder="Gift Code"
      >
      <button
        type="submit"
        class="rounded-r-lg bg-blue-400 text-gray-800 font-bold p-3 uppercase border-blue-500 border-t border-b border-r"
        @submit.prevent="addCode"
      >
        Add
      </button>
    </form>
    <div class="flex justify-center">
      <div class="bg-white shadow-xl rounded-lg w-4/5 lg:w-1/2">
        <ul class="divide-y divide-gray-300 w-full">
          <li
            v-for="code in giftCodes"
            :key="code"
            class="p-2 hover:bg-gray-50 cursor-pointer flex justify-around"
          >
            <span class="p-1 w-3/4">
              {{ code }}
            </span>
            <span
              class="p-1 text-sm border rounded-sm border-blue-400 text-blue-400 cursor-pointer"
              @click="removeGiftCard(code)"
            >
              Delete</span>
          </li>
        </ul>
      </div>
    </div>
    <div class="mt-10 text-center">
      <ButtonSecondary
        btn-type="danger"
        text="Back"
        @click="$store.dispatch('BACK')"
      />
      <button-secondary
        btn-type="Info"
        text="apply"
        @click="applyGiftCard"
      >
        <LoaderInsideButton
          :is-loading="isApplingGift"
          class="text-blue-400"
        />
      </button-secondary>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import LoaderInsideButton from "./partials/LoaderInsideButton.vue";
import ButtonSecondary from "./partials/ButtonSecondary.vue";

export default {
  components: { LoaderInsideButton, ButtonSecondary },
  data() {
    return {
      searchKey: "",
      giftCode: "",
      isApplingGift: false
    };
  },
  computed: {
    ...mapGetters(["giftCodes"])
  },

  methods: {
    applyGiftCard() {
      this.isApplingGift = true;
      this.$store
        .dispatch("GET_BENEFIT")
        .then(() => {
          this.isApplingGift = false;
          this.$store.dispatch("BACK");
        })
        .catch(err => {
          console.log(err);
        });
    },
    addCode() {
      if (!this.giftCode) {
        return;
      }
      this.$store.commit("addGiftCode", this.giftCode);
      this.giftCode = "";
    },
    removeGiftCard(code) {
      this.$store.commit("removeGiftCode", code);
    }
  }
};
</script>
