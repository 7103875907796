<template>
  <div>
    <!-- <h2 class="w-full bg-gray-100 text-gray-400 font-bold">Member Details</h2> -->
    <div class="p-2 pt-6 px-2 md:px-4 lg:px-8">
      <h3
        v-if="member.firstName || member.lastName"
        class="text-center text-xl capitalize text-gray-900 font-medium leading-8 w-full bg-como-100 text-gray-400 font-bold"
      >
        {{ member.firstName + " " + member.lastName }}
      </h3>
      <h3 v-else>
        No Name
      </h3>
      <table class="text-sm my-3 text-left">
        <tbody>
          <tr v-if="member.phoneNumber">
            <td class="px-2 py-2 text-gray-500 font-semibold">
              Phone Number
            </td>
            <td class="px-2 py-2 font-bold">
              {{ member.phoneNumber }}
            </td>
          </tr>
          <tr v-if="member.email">
            <td class="px-2 py-2 text-gray-500 font-semibold">
              Member Email
            </td>
            <td class="px-2 py-2 font-bold">
              {{ member.email }}
            </td>
          </tr>
          <tr v-if="member.govID">
            <td class="px-2 py-2 text-gray-500 font-semibold">
              Member GOVT ID
            </td>
            <td class="px-2 py-2 font-bold">
              {{ member.govID }}
            </td>
          </tr>
          <tr v-if="member.tags">
            <td class="px-2 py-2 text-gray-500 font-semibold">
              Member Tags
            </td>
            <td class="px-2 py-2 font-bold">
              {{ member.tags.join(", ") }}
            </td>
          </tr>
          <tr v-if="member.pointsBalance && member.pointsBalance.usedByPayment">
            <td class="px-2 py-2 text-gray-500 font-semibold">
              Points Balance
            </td>
            <td class="px-2 py-2 font-bold">
              {{
                $filters.convertFromCent(member.pointsBalance.balance.monetary)
              }}
            </td>
          </tr>
          <tr v-if="member.creditBalance && member.creditBalance.usedByPayment">
            <td class="px-2 py-2 text-gray-500 font-semibold">
              Credit Balance
            </td>
            <td class="px-2 py-2">
              {{
                $filters.convertFromCent(member.creditBalance.balance.monetary)
              }}
            </td>
          </tr>
        </tbody>
      </table>
      <div class="pt-4 m-auto inline-block">
        <div
          v-for="asset in redeemAssetsDetails"
          :key="asset.key"
          class="flex justify-center items-center"
        >
          <div
            v-if="asset.redeemable"
            class="px-4 border rounded border-blue-800"
          >
            <span v-if="asset.code" class="text-blue-800">
              {{ asset.code + " : " + asset.name }}
            </span>

            <span v-else class="text-blue-800">
              {{ asset.name }}
            </span>

            <span class="pl-2 text-black"> Applied </span>
          </div>
          <div
            v-else
            class="px-4 border rounded border-red-800 flex justify-center items-center"
          >
            <span class="text-red-800 ">
              {{ asset.code + " : " + asset.nonRedeemableCause.message }}
            </span>
          </div>
          <span class="px-3">
            <button
              class="cursor-pointer hover:opacity-70"
              @click="
                asset.code ? removeGiftCode(asset) : removeAssetCard(asset)
              "
            >
              <delete-icon class="w-6 h-6" />
            </button>
          </span>
        </div>
      </div>
    </div>
    <div class="pt-4 flex justify-center">
      <ButtonSecondary
        text="Back"
        btn-type="gray"
        size="small"
        @click="$store.dispatch('BACK')"
      />
      <router-link
        :to="{ name: 'AssetCard' }"
        class="mr-2 shadow-md font-medium py-1 px-3 bg-blue-100 text-blue-400 border border-blue-400 cursor-pointer rounded text-sm text-center hover:bg-blue-200 hover:shadow-md self-center h-16 w-32 flex justify-center items-center font-bold"
      >
        Show Gift
      </router-link>

      <router-link
        :to="{ name: 'UseCode' }"
        class="mr-2 shadow-md font-medium py-1 px-3 bg-blue-100 text-blue-400 border border-blue-400 cursor-pointer rounded text-sm text-center hover:bg-blue-200 hover:shadow-md h-16 w-32 flex justify-center items-center font-bold"
      >
        Use Code
      </router-link>
      <router-link
        :to="{ name: 'RedeemWithComo' }"
        class="mr-2 shadow-md font-medium py-1 px-3 text-white border border-green-400 cursor-pointer rounded text-sm text-center bg-gradient-to-br from-green-200 to-green-600 hover:shadow-md h-16 w-32 flex justify-center items-center font-bold"
      >
        Proceed
      </router-link>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import DeleteIcon from "../assets/icons/delete.vue";
import ButtonSecondary from "./partials/ButtonSecondary.vue";
export default {
  components: { DeleteIcon, ButtonSecondary },
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["member", "companyId"]),
    appliedAssets: {
      get() {
        return this.$store.getters.appliedAssets;
      },
      set(value) {
        return this.$store.commit("addAsset", value);
      },
    },
    /**Actual Applied gifts details */
    redeemAssetsDetails() {
      return this.$store.getters.redeemAssetsDetails;
    },
  },
  mounted() {
    this.$store.dispatch("STOP_LOADING");
  },
  methods: {
    /**
     * @description this method will remove given asset and will
     * fetch benefit based upon new assetDetails
     * @param Object asset
     * @returns void
     */
    removeAssetCard(asset) {
      this.$store.commit("removeAsset", asset);
      this.applyGiftCard();
    },
    /**
     * @description this method will remove given asset and will
     * fetch benefit based upon new assetDetails
     * @param Object asset
     * @returns void
     */
    removeGiftCode(asset) {
      this.$store.commit("removeGiftCode", asset);
      this.applyGiftCard();
    },
    /**
     * This method will apply asset and will update benefit details
     */
    applyGiftCard() {
      this.$store.dispatch("START_LOADING");
      this.$store
        .dispatch("GET_BENEFIT")
        .then(() => {
          this.$store.dispatch("STOP_LOADING");
        })
        .catch((err) => {
          console.log(err);
        });
    },
    /**
     * Dismiss page and go back to member details page
     */
    dismiss() {
      try {
        if (this.companyId) {
          window.LSLoyalty.close();
        } else {
          window.pos_close();
        }
      } catch (err) {
        console.error(err);
      }
    },
  },
};
</script>
