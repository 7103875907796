<template>
  <form class="w-full flex justify-center" @submit.prevent="getMemberDetails">
    <div class="flex-auto w-full max-w-lg p-10 pb-20">
      <ErrorResponseHandle :errors="errors" />
      <div
        v-if="activeIdentifiers.find((id) => id == 'phoneNumber')"
        class="w-full my-4 bg-white p-1 flex border border-gray-200 rounded"
      >
        <input
          v-model="identificationFormData.phoneNumber"
          type="text"
          inputmode="numeric"
          pattern="\d+"
          enterkeyhint="go"
          placeholder="Phone Number"
          autocomplete="phoneNumber"
          autofocus
          class="p-1 px-2 appearance-none outline-none w-full text-gray-800 disabled:opacity-50"
        />
        <button
          v-if="identificationFormData.phoneNumber"
          type="button"
          class="self-center text-red-400 hover:opacity-75 focus:outline-none outline-none"
          @click="identificationFormData.phoneNumber = ''"
        >
          <CloseButtonIcon />
        </button>
      </div>
      <div
        v-if="activeIdentifiers.find((id) => id == 'email')"
        class="w-full my-4 bg-white p-1 flex border border-gray-200 rounded"
      >
        <input
          v-model="identificationFormData.email"
          placeholder="Email"
          type="email"
          autocomplete="email"
          autofocus
          class="p-1 px-2 appearance-none outline-none w-full text-gray-800 disabled:opacity-50"
        />
        <button
          v-if="identificationFormData.email"
          type="button"
          class="self-center text-red-400 hover:opacity-75 focus:outline-none outline-none"
          @click="identificationFormData.email = ''"
        >
          <CloseButtonIcon />
        </button>
      </div>
      <div
        v-if="activeIdentifiers.find((id) => id == 'memberId')"
        class="w-full my-4 bg-white p-1 flex border border-gray-200 rounded"
      >
        <input
          v-model="identificationFormData.memberId"
          placeholder="Member ID"
          class="p-1 px-2 appearance-none outline-none w-full text-gray-800 disabled:opacity-50"
        />
        <button
          v-if="identificationFormData.memberId"
          type="button"
          class="self-center text-red-400 hover:opacity-75 focus:outline-none outline-none"
          @click="identificationFormData.memberId = ''"
        >
          <CloseButtonIcon />
        </button>
      </div>
      <div
        v-if="activeIdentifiers.find((id) => id == 'appClientId')"
        class="w-full my-4 bg-white p-1 flex border border-gray-200 rounded"
      >
        <input
          v-model="identificationFormData.appClientId"
          placeholder="App Client ID"
          class="p-1 px-2 appearance-none outline-none w-full text-gray-800 disabled:opacity-50"
        />
        <button
          v-if="identificationFormData.appClientId"
          type="button"
          class="self-center text-red-400 hover:opacity-75 focus:outline-none outline-none"
          @click="identificationFormData.appClientId = ''"
        >
          <CloseButtonIcon />
        </button>
      </div>
      <div
        v-if="activeIdentifiers.find((id) => id == 'customIdentifier')"
        class="w-full my-4 bg-white p-1 flex border border-gray-200 rounded"
      >
        <input
          v-model="identificationFormData.customIdentifier"
          placeholder="Custom Identifier"
          class="p-1 px-2 appearance-none outline-none w-full text-gray-800 disabled:opacity-50"
        />
        <button
          v-if="identificationFormData.customIdentifier"
          type="button"
          class="self-center text-red-400 hover:opacity-75 focus:outline-none outline-none"
          @click="identificationFormData.customIdentifier = ''"
        >
          <CloseButtonIcon />
        </button>
      </div>
      <div class="text-right flex items-center justify-end">
        <svg
          class="text-blue-700 w-4 h-4"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            d="M2 6H0v2h2v2h2V8h2V6H4V4H2v2zm7 0a3 3 0 0 1 6 0v2a3 3 0 0 1-6 0V6zm11 9.14A15.93 15.93 0 0 0 12 13c-2.91 0-5.65.78-8 2.14V18h16v-2.86z"
          ></path>
        </svg>
        <router-link
          :to="{ name: 'MemberRegistration' }"
          class="text-blue-700 p-1 px-2 cursor-pointer hover:text-blue-800 hover:font-bold"
        >
          Register Member
        </router-link>
      </div>
      <div class="mt-6 flex justify-center">
        <ButtonSecondary
          btn-type="danger"
          :text="accountId ? `Back to Lightspeed` : `Cancel`"
          @click="cancelLoyalty()"
        />
        <ButtonSecondary
          v-if="
            companyId &&
              (lightSpeedSuccessHandler.customer == null
                ? false
                : lightSpeedSuccessHandler.customer.email == 'como@como.com')
          "
          btn-type="info"
          text="Back"
          @click="$store.dispatch('BACK')"
        />
        <ButtonSecondary
          v-if="
            businessLocationId &&
              (lightSpeedSuccessHandler.consumer == null
                ? false
                : lightSpeedSuccessHandler.consumer.email == 'como@como.com')
          "
          btn-type="info"
          text="Back"
          @click="$store.dispatch('BACK')"
        />
        <ButtonSecondary
          text="Find Member"
          type="submit"
          :disabled="!isValid"
          @click.prevent="getMemberDetails"
        />
        <ButtonSecondary text="Scan code" @click="initiateScanning()" />
      </div>
      <div>
        <vue-final-modal
          v-model="showScannerModal"
          classes="modal-container rounded"
          content-class="modal-content"
        >
          <div class="modal__content bg-como-100">
            <button class="modal__close" @click="showScannerModal = false">
              <svg
                class="h-5 w-5 font-bold"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#ffffff"
                stroke-width="2.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <line x1="18" y1="6" x2="6" y2="18"></line>
                <line x1="6" y1="6" x2="18" y2="18"></line>
              </svg>
            </button>
            <button class="switch-camera-btn" @click="switchCamera">
              <svg
                class="h-5 w-5 font-bold"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#ffffff"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="M17 2.1l4 4-4 4" />
                <path d="M3 12.2v-2a4 4 0 0 1 4-4h12.8M7 21.9l-4-4 4-4" />
                <path d="M21 11.8v2a4 4 0 0 1-4 4H4.2" />
              </svg>
            </button>
            <!-- <p>{{ decodedString }}</p> -->
            <div class="flex flex-col justify-center item-center">
              <div class="text-center font-bold text-gray-500">
                Please scan the QR Code here
              </div>
              <div class="p-2">
                <qrcode-stream
                  v-if="showScannerModal"
                  @init="onInit"
                  @decode="onDecode"
                >
                  <div
                    v-if="loading"
                    class="loading-indicator h-full flex items-center justify-center"
                  >
                    Loading...
                  </div>
                  <div v-if="!error && !loading" class="scanner"></div>
                </qrcode-stream>
              </div>
            </div>
            <p v-if="!loading" class="font-semibold">
              {{ error }}
            </p>
          </div>
        </vue-final-modal>
      </div>
    </div>
  </form>
</template>
<script>
import CloseButtonIcon from "./partials/closeButtonIcon.vue";
import { mapGetters } from "vuex";
import ButtonSecondary from "./partials/ButtonSecondary.vue";
import ErrorResponseHandle from "./errors/ErrorResponseHandle.vue";
import { VueFinalModal } from "vue-final-modal";
import { QrcodeStream } from "vue3-qrcode-reader";
export default {
  name: "MemberIdentification",
  components: {
    CloseButtonIcon,
    ButtonSecondary,
    ErrorResponseHandle,
    VueFinalModal,
    QrcodeStream,
  },
  props: {},
  data() {
    return {
      errors: [],
      activeIdentifiers: [],
      showScannerModal: false,
      error: "",
      decodedString: "",
      camera: "auto",
      noRearCamera: false,
      noFrontCamera: false,
      loading: false,
    };
  },
  computed: {
    /**
     * The mapGetters helper simply maps store getters to local computed properties:
     * @see https://vuex.vuejs.org/guide/getters.html#the-mapgetters-helper
     */
    ...mapGetters([
      "vatInclusive",
      "identificationFormData",
      "registrationFormData",
      "member",
      "applicationSettings",
      "businessLocationId",
      "companyId",
      "accountId",
      "callBackUrlRSeries",
      "lightSpeedSuccessHandler",
      "isTestDataMode",
    ]),

    /**
     * Form data
     * @return Object
     */
    identificationFormData: {
      get() {
        return this.$store.getters.identificationFormData;
      },
      set(value) {
        this.$store.dispatch("updateIdentificationFormData", value);
      },
    },
    isValid() {
      return (
        this.identificationFormData.phoneNumber +
          this.identificationFormData.email +
          this.identificationFormData.memberId +
          this.identificationFormData.appClientId +
          this.identificationFormData.customIdentifier.trim() !==
        ""
      );
    },
  },

  mounted() {
    this.$store.dispatch("START_LOADING");

    /** Get identifiers details */
    this.$store
      .dispatch("GET_APPLICATION_SETTINGS")
      .then((response) => {
        this.activeIdentifiers = response.data.data.identifiers
          .filter((id) => id.active)
          .map((id) => id.name);
        // this.validate();
        this.$store.dispatch("STOP_LOADING");
      })
      .catch((error) => {
        this.errors = [];
        console.error(error.response);
        if (error.response) {
          for (let i in error.response.data) {
            if (typeof error.response.data[i] == "object") {
              for (let j in error.response.data[i]) {
                this.errors.push(error.response.data[i][j]);
              }
            }
          }
          this.$store.dispatch("STOP_LOADING");
          // }
        } else if (error.request) {
          this.errors.push(error.message);
          this.$store.dispatch("STOP_LOADING");
        } else {
          this.errors.push(error.message);
          this.$store.dispatch("STOP_LOADING");
        }
      });
  },
  methods: {
    initiateScanning() {
      this.showScannerModal = true;
      // this.onInit()
    },
    confirm() {
      // some code...
      this.show = false;
    },
    cancel(close) {
      // some code...
      close();
    },

    async onInit(promise) {
      // show loading indicator
      this.camera = this.isTestDataMode ? "auto" : "rear";
      this.loading = true;

      try {
        const { capabilities } = await promise;
        console.log("capabilities", capabilities);
        this.error = "";
        // successfully initialized
      } catch (error) {
        const triedFrontCamera = this.camera === "front";
        const triedRearCamera = this.camera === "rear";

        const cameraMissingError = error.name === "OverconstrainedError";

        if (triedRearCamera && cameraMissingError) {
          this.noRearCamera = true;
        }

        if (triedFrontCamera && cameraMissingError) {
          this.noFrontCamera = true;
        }
        if (error.name === "NotAllowedError") {
          this.error = "user denied camera access permisson";
        } else if (error.name === "NotFoundError") {
          this.error = "no suitable camera device installed";
        } else if (error.name === "NotSupportedError") {
          this.error = "page is not served over HTTPS (or localhost)";
        } else if (error.name === "NotReadableError") {
          this.error = "maybe camera is already in use";
        } else if (error.name === "OverconstrainedError") {
          // this.error = "did you requested the front camera although there is none?"
          if (triedRearCamera && cameraMissingError) {
            this.error = "You don't seem to have a rear camera on your device";
          }
          if (triedFrontCamera && cameraMissingError) {
            this.error = "You don't seem to have a front camera on your device";
          }
        } else if (error.name === "StreamApiNotSupportedError") {
          this.error = "browser seems to be lacking features";
        }
      } finally {
        // hide loading indicator
        this.loading = false;
      }
    },
    onDecode(decodedString) {
      this.identificationFormData.appClientId = "";
      this.identificationFormData.customIdentifier = "";
      this.decodedString = decodedString;
      console.log(this.identificationFormData);
      if (decodedString.length == 4) {
        this.identificationFormData.appClientId = decodedString;
      } else if (decodedString.length > 4) {
        this.identificationFormData.customIdentifier = decodedString;
      }
      console.log(this.identificationFormData);
      this.showScannerModal = false;
      this.getMemberDetails();

      // window.location.replace(decodedString)
    },
    switchCamera() {
      this.showScannerModal = false;
      switch (this.camera) {
        case "auto":
          this.camera = "front";
          break;
        case "front":
          this.camera = "rear";
          break;
        case "rear":
          this.camera = "auto";
          break;
      }
      this.showScannerModal = true;
    },

    /** * Cancel the loyalty and closes the window. */

    cancelLoyalty() {
      try {
        if (this.companyId) {
          window.LSLoyalty.close();
        } else if (this.businessLocationId) {
          window.pos_close();
        } else {
          location.replace(this.callBackUrlRSeries);
        }
      } catch (err) {
        console.error(err);
      }
    },
    // validate() {
    //   this.isValid =
    //     this.identificationFormData.phoneNumber +
    //       this.identificationFormData.email +
    //       this.identificationFormData.memberId +
    //       this.identificationFormData.appClientId +
    //       this.identificationFormData.customIdentifier.trim() !==
    //     "";
    // },

    /**
     * this method will dispatch GET_MEMBER_DETAILS and
     * redirect to member details page if respose succeed
     */
    getMemberDetails() {
      if (!this.isValid) {
        return;
      }
      this.$store.dispatch("START_LOADING");
      this.$store
        .dispatch("GET_MEMBER_DETAILS")
        .then(() => {
          // Add consumer to como in K Series
          // if (this.businessLocationId) {
          //   let consumerData = {
          //     firstName: this.member.firstName,
          //     lastName: this.member.lastName,
          //     phoneNumber: this.member.phoneNumber,
          //     email: this.member.email,
          //     memberId: this.member.memberId,
          //     commonExtId: this.member.commonExtId
          //   };
          //   try {
          //     window.pos_setConsumer(consumerData);
          //   } catch (err) {
          //     this.errors.push(err);
          //   }
          // }
          this.$store.dispatch("STOP_LOADING");
          this.$router.push({ name: "MemberDetails" });
        })
        .catch((error) => {
          this.errors = [];
          console.error(error.response);

          if (error.response) {
            for (let i in error.response.data) {
              if (typeof error.response.data[i] == "object") {
                for (let j in error.response.data[i]) {
                  this.errors.push(error.response.data[i][j]);
                }
              }
            }
            this.$store.dispatch("STOP_LOADING");
            // }
          } else if (error.request) {
            this.errors.push(error.message);
            this.$store.dispatch("STOP_LOADING");
          } else {
            this.errors.push(error.message);
            this.$store.dispatch("STOP_LOADING");
          }
        });
    },
  },
};
</script>

<style scoped>
:deep(.modal-container) {
  display: flex;
  justify-content: center;
  align-items: center;
}
:deep(.modal-content) {
  position: relative;
  display: flex;
  flex-direction: column;
  max-height: 90%;
  margin: 0 1rem;
  padding: 2rem 0rem 0rem 0rem;
  /* border: 1px solid #e2e8f0; */
  border-radius: 0.25rem;
  background: #07a3c8;
}
.modal__title {
  margin: 0 2rem 0 0;
  font-size: 1.5rem;
  font-weight: 700;
}
.modal__action {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  padding: 1rem 0 0;
}
.modal__close {
  position: absolute;
  /* top: -1.5rem; */
  top: 0.3rem;
  right: 0.5rem;
}

.switch-camera-btn {
  position: absolute;
  /* top: -1.5rem; */
  top: 0.3rem;
  left: 0.5rem;
}
</style>

<style scoped>
.dark-mode div:deep(.modal-content) {
  border-color: #2d3748;
  background-color: #1a202c;
}

.loading-indicator {
  font-weight: bold;
  font-size: 2rem;
  text-align: center;
}

.scanner {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  box-shadow: inset 0px 0px 0px 50px rgba(0, 0, 0, 0.5);
}
.scanner::after {
  content: "";
  position: absolute;
  height: 70%;
  display: block;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  margin: auto 20%;
  border-right: 3px solid rgb(23, 162, 74);
  animation: 3s infinite ease-in-out roll;
}

@keyframes roll {
  0% {
    transform: translateX(-100%);
  }
  50% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}
</style>
