<template>
  <div class="border p-4">
    <h1 class="text-lg font-bold">
      Log Data
    </h1>
    <p>
      {{ logData }}
    </p>
  </div>
</template>
<script >
export default {
  data(){
    return{
      logData:null
    }
  },
    mounted(){
        this.$store.dispatch('STOP_LOADING');
        try{
          this.logData=window.parent.EposnowAPI.getDeviceID;
        } catch (error) {
          this.logData=error;
        }
    }
}
</script>

<style scoped>
</style>