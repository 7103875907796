export default {
  consumer: {
    city: "",
    firstName: "John",
    addressLine1: "",
    // email: "john@como.co",
    email: "",
    identifier: "089ba81e-9ab1-41d0-8304-0af5280d41c4",
    addressLine2: "",
    consumer: "2501719",
    zipCode: "",
    lastName: "Doe",
  },
  totalAmount: 11500,
  transactionLines: [
    {
      quantity: 100,
      unitAmount: 1450,
      amount: 1450,
      modificationDate: "2022-05-06T09:32:29.655Z",
      accountingGroupName: "Cocktails",
      parentLine: "??",
      accountingGroupId: "194467529229709",
      discounts: [],
      isDraft: true,
      identifier: "dfRZtCn_RxyNyKAyKpbpHQ==",
      itemSku: "307",
      text: "Bloody Mary",
      itemIdentifier: "194467529229721",
      creationDate: "2021-12-16T09:32:29.655Z",
      fiscalIdentifier: null,
    },
    {
      quantity: 100,
      unitAmount: 550,
      amount: 550,
      modificationDate: "2021-12-16T09:32:30.016Z",
      accountingGroupName: "Heissgetränke",
      parentLine: "??",
      accountingGroupId: "194467529230220",
      discounts: [],
      isDraft: true,
      identifier: "tQlaPYhMThCWFxwNXlsQ0g==",
      itemSku: "438",
      text: "Cappuccino",
      itemIdentifier: "194467529230218",
      creationDate: "2021-12-16T09:32:30.016Z",
      fiscalIdentifier: null,
    },
    {
      quantity: 100,
      unitAmount: 3700,
      amount: 3700,
      modificationDate: "2021-12-16T09:32:30.196Z",
      accountingGroupName: "Kalte Küche",
      parentLine: "??",
      accountingGroupId: "194467529228484",
      discounts: [],
      isDraft: true,
      identifier: "nhJVjXYVQrqaXoeyH2WJHw==",
      itemSku: "69",
      text: "Rindsfilet Tatar gross",
      itemIdentifier: "194467529228721",
      creationDate: "2021-12-16T09:32:30.196Z",
      fiscalIdentifier: null,
    },
    {
      quantity: 100,
      unitAmount: 1450,
      amount: 1450,
      modificationDate: "2021-12-16T09:32:30.401Z",
      accountingGroupName: "Cocktails",
      parentLine: "??",
      accountingGroupId: "194467529229709",
      discounts: [],
      isDraft: true,
      identifier: "MeC3xctPRLyb0hiHR9_llQ==",
      itemSku: "307",
      text: "Bloody Mary",
      itemIdentifier: "194467529229721",
      creationDate: "2021-12-16T09:32:30.401Z",
      fiscalIdentifier: null,
    },
    {
      quantity: 100,
      unitAmount: 1450,
      amount: 1450,
      modificationDate: "2021-12-16T09:32:30.510Z",
      accountingGroupName: "Cocktails",
      parentLine: "??",
      accountingGroupId: "194467529229709",
      discounts: [],
      isDraft: true,
      identifier: "ng1N36E7RDeLrLyFU_7-zA==",
      itemSku: "307",
      text: "Bloody Mary",
      itemIdentifier: "194467529229721",
      creationDate: "2021-12-16T09:32:30.510Z",
      fiscalIdentifier: null,
    },
    {
      quantity: 100,
      unitAmount: 1450,
      amount: 1450,
      modificationDate: "2021-12-16T09:32:30.637Z",
      accountingGroupName: "Cocktails",
      parentLine: "??",
      accountingGroupId: "194467529229709",
      discounts: [],
      isDraft: true,
      identifier: "aUL01CL7TqKeivzMh5PaeQ==",
      itemSku: "307",
      text: "Bloody Mary",
      itemIdentifier: "194467529229721",
      creationDate: "2021-12-16T09:32:30.637Z",
      fiscalIdentifier: null,
    },
    {
      quantity: 100,
      unitAmount: 1450,
      amount: 1450,
      modificationDate: "2021-12-16T09:32:30.947Z",
      accountingGroupName: "Cocktails",
      parentLine: "??",
      accountingGroupId: "194467529229709",
      discounts: [],
      isDraft: true,
      identifier: "5Ty1exUBQYG1tvgoWaw4KA==",
      itemSku: "307",
      text: "Bloody Mary",
      itemIdentifier: "194467529229721",
      creationDate: "2021-12-16T09:32:30.947Z",
      fiscalIdentifier: null,
    },
  ],
  clientCount: 0,
  currentInsertionPhase: 0,
  discounts: [],
  isDraft: true,
  profileId: "",
  identifier: "t3co1oa5Ryagi95bfHVyRwx=",
  profileName: "",
  receiptPreview: "",
  paidAmount: 0,
  openDate: "2022-05-06T09:32:27.912Z",
  paymentLines: [],
  name: "",
  fiscalIdentifier: null,
};
