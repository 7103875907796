<template>
  <div class="p-4 px-8">
    <div class="bg-gray-100 text-black rounded">
      <h1 class="text-lg p-2 px-4">
        Coupon Code
      </h1>
    </div>
    <ErrorResponseHandle :errors="errors" />

    <div class="flex justify-end pt-4">
      <div class="bg-gray-200 rounded">
        <div class="inline-flex rounded">
          <input
            id="activeGifts"
            v-model="giftRedeemable"
            :value="true"
            type="radio"
            hidden
          >
          <label
            for="activeGifts"
            class="
              radio
              text-center
              self-center
              text-sm
              py-1
              px-3
              rounded
              cursor-pointer
              hover:opacity-75
            "
          >
            Active Gifts</label>
        </div>
        <div class="inline-flex rounded">
          <input
            id="inactiveGifts"
            v-model="giftRedeemable"
            :value="false"
            type="radio"
            hidden
          >
          <label
            for="inactiveGifts"
            class="
              radio
              text-center
              self-center
              text-sm
              py-1
              px-3
              rounded
              cursor-pointer
              hover:opacity-75
            "
          >Inactive Gifts</label>
        </div>
      </div>
    </div>
    <label
      v-for="(asset, index) in filteredAsset"
      :key="asset.key"
      class="flex items-center w-full mt-3 px-4 hover:bg-blue-200"
      :class="[
        asset.redeemable ? 'text-blue-400' : 'bg-opacity-80 text-gray-400',
        psStartIndex != 0 ? 'border-t' : '',
        psLastIndex == index ? 'border-b-2' : ''
      ]"
    >
      <input
        :id="asset.key"
        v-model="appliedCouponKeys"
        type="checkbox"
        :for="asset.key"
        :value="asset.key"
        class="h-5 w-5 text-yellow-600"
        :disabled="!asset.redeemable"
      >
      <asset-element :asset="asset" />
    </label>
    <div class="mt-8 flex justify-center">
      <ButtonSecondary
        btn-type="danger"
        text="cancel"
        :disabled="isApplingGift"
        @click="$store.dispatch('BACK')"
      />
      <button-secondary
        text="Apply"
        :disabled="isApplingGift"
        @click="applyGiftCard"
      >
        <LoaderInsideButton
          class="text-blue-400"
          :is-loading="isApplingGift"
        />
      </button-secondary>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import ErrorResponseHandle from "./errors/ErrorResponseHandle.vue";
import AssetElement from "./partials/AssetCardElement.vue";
import ButtonSecondary from "./partials/ButtonSecondary.vue";
import LoaderInsideButton from "./partials/LoaderInsideButton.vue";
export default {
  components: {
    AssetElement,
    ErrorResponseHandle,
    ButtonSecondary,
    LoaderInsideButton
  },
  data() {
    return {
      appliedCouponKeys: [],
      isApplingGift: false,
      giftRedeemable: true,
      filteredAsset: [],
      errors: []
    };
  },
  computed: {
    ...mapGetters(["member", "vatInclusive", "appliedAssets"]),
    assets() {
      return this.member.assets;
    },
    psStartIndex() {
      return this.member.assets.findIndex(asset => asset.key.startsWith("ps_"));
    },
    psLastIndex() {
      return (
        this.psStartIndex +
        this.assets.filter(asset => asset.key.startsWith("ps_")).length -
        1
      );
    }
  },
  watch: {
    giftRedeemable(newValue) {
      this.filteredAsset = this.assets.filter(asset => {
        return asset.redeemable == newValue;
      });
    }
  },
  mounted() {
    /** Show GiftCards based on default value of giftRedeemable */
    this.filteredAsset = this.assets.filter(
      asset => asset.redeemable == this.giftRedeemable
    );

    this.appliedCouponKeys = [];
    for (const asset in this.appliedAssets) {
      this.appliedCouponKeys.push(this.appliedAssets[asset]["key"]);
    }
  },
  methods: {
    applyGiftCard() {
      this.isApplingGift = true;
      this.errors = [];
      const newAssetArray = this.assets.filter(asset => {
        return this.appliedCouponKeys.includes(asset.key);
      });
      this.$store.commit("updateAsset", newAssetArray);
      this.$store
        .dispatch("GET_BENEFIT")
        .then(response => {
          this.$store.commit("updateBenefitDetails", response.data);
          this.isApplingGift = false;
          this.$router.push({ name: "MemberDetails" });
        })
        .catch(error => {
          this.isApplingGift = false;
          if (error.response) {
            this.errors.push(error.response.statusText);
            this.$store.dispatch("STOP_LOADING");
            // }
          } else if (error.request) {
            this.errors.push(error.message);
            this.$store.dispatch("STOP_LOADING");
          } else {
            this.errors.push(error.message);
            this.$store.dispatch("STOP_LOADING");
          }
          console.log(error);
        });
    }
  }
};
</script>
<style>
input:checked ~ .radio {
  color: white;
  background-color: rgb(173, 166, 166);
}
</style>
