<template>
  <div class="w-full flex justify-center">
    <div class="flex-auto w-full max-w-lg p-10 pb-20">
      <ErrorResponseHandle :errors="errors" />
      <SuccessResponseHandle :successes="successes" />

      <div class="w-full my-4 bg-white p-1 flex border border-gray-200 rounded">
        <input
          v-model="registrationFormData.phoneNumber"
          placeholder="Phone Number"
          inputmode="numeric"
          class="p-1 px-2 appearance-none outline-none w-full text-gray-800"
        >
      </div>

      <div class="w-full my-4 bg-white p-1 flex border border-gray-200 rounded">
        <input
          v-model="registrationFormData.firstName"
          placeholder="Member First Name"
          class="p-1 px-2 appearance-none outline-none w-full text-gray-800"
        >
      </div>
      <div class="w-full my-4 bg-white p-1 flex border border-gray-200 rounded">
        <input
          v-model="registrationFormData.lastName"
          placeholder="Member Last Name"
          class="p-1 px-2 appearance-none outline-none w-full text-gray-800"
        >
      </div>
      <div class="w-full my-4 bg-white p-1 flex border border-gray-200 rounded">
        <input
          v-model="registrationFormData.email"
          placeholder="Member email"
          type="email"
          inputmode="email"
          class="p-1 px-2 appearance-none outline-none w-full text-gray-800"
        >
      </div>
      <!-- <div class="w-full my-4 bg-white p-1 flex border border-gray-200 rounded">
        <input
          v-model="registrationFormData.memberId"
          placeholder="Mmeber ID"
          class="p-1 px-2 appearance-none outline-none w-full text-gray-800"
        >
      </div> -->
      <!-- <div class="w-full my-4 bg-white p-1 flex border border-gray-200 rounded">
        <input
          v-model="registrationFormData.govID"
          placeholder="GovID"
          class="p-1 px-2 appearance-none outline-none w-full text-gray-800"
        >
      </div> -->
      <div class="mt-6">
        <ButtonSecondary
          type="danger"
          text="Back"
          @click="dismiss"
        />

        <ButtonSecondary
          :disabled="!isValid"
          btn-type="info"
          text="Submit"
          @click="registerMember"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import ErrorResponseHandle from "./errors/ErrorResponseHandle.vue";
import SuccessResponseHandle from "./errors/SuccessResponseHandle.vue";
import ButtonSecondary from "./partials/ButtonSecondary.vue";
export default {
  name: "MemberRegistartionCard",
  components: { ButtonSecondary, SuccessResponseHandle, ErrorResponseHandle },
  props: {},
  data() {
    return {
      loading: true,
      errors: [],
      successes: []
    };
  },
  computed: {
    ...mapGetters([
      "registrationFormData",
      "lightSpeedSuccessHandler",
      "businessLocationId"
    ]),
    /**
     * validate Registartion form
     * @return Boolean
     */
    isValid() {
      return (
        (this.registrationFormData.phoneNumber &&
          this.registrationFormData.phoneNumber.trim() != "") ||
        (this.registrationFormData.email &&
          this.registrationFormData.email.trim() != "")
      );
    }
  },
  mounted() {
    if (this.businessLocationId) {
      this.registrationFormData.firstName = this.lightSpeedSuccessHandler.consumer.firstName;
      this.registrationFormData.lastName = this.lightSpeedSuccessHandler.consumer.lastName;
      this.registrationFormData.email = this.lightSpeedSuccessHandler.consumer.email;
      this.registrationFormData.phoneNumber = this.lightSpeedSuccessHandler.consumer.phoneNumber;
    } else {
      this.registrationFormData.firstName = this.lightSpeedSuccessHandler.customer.firstname;
      this.registrationFormData.lastName = this.lightSpeedSuccessHandler.customer.lastname;
      this.registrationFormData.email = this.lightSpeedSuccessHandler.customer.email;
      this.registrationFormData.phoneNumber = this.lightSpeedSuccessHandler.customer.telephone;
    }
    this.$store.dispatch("STOP_LOADING");
  },
  methods: {
    /**
     * Reset Member Details and go back
     */
    dismiss() {
      this.$store.commit("updateMember", {});
      this.$store.dispatch("BACK");
    },
    /**
     * Register new Como Member
     * @return void
     * @param void
     * @uses $store.getters.member
     */
    registerMember() {
      this.$store.dispatch("START_LOADING");
      // remove all previous Errors
      this.errors = [];

      this.$store
        .dispatch("REGISTER_MEMBER")
        .then(response => {
          if (response.status > 200 && response.status < 300) {
            this.$store.getters.identificationFormData.phoneNumber = this.$store.getters.registrationFormData.phoneNumber;
            this.$store.dispatch("GET_MEMBER_DETAILS").then(res => {
              this.successes.push("Member Registered");
              this.$store.commit("updateMember", res.data.membership);
              return this.$router.push({ name: "MemberDetails" });
            });
          }

          if (response.data.errors.length) {
            response.data.errors.forEach(error => {
              this.errors.push(error.message);
            });
          }
          this.$store.dispatch("STOP_LOADING");
        })
        .catch(error => {
          for (let i in error.response.data) {
            if (typeof error.response.data[i] == "object") {
              for (let j in error.response.data[i]) {
                this.errors.push(error.response.data[i][j]);
              }
            }
          }
          this.$store.dispatch("STOP_LOADING");
        });
    }
  }
};
</script>
