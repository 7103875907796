<template>
  <div class="block lg:flex justify-center items-center">
    <div class="border p-4 flex flex-col items-center">
      <p>Camera Test</p>
      <!-- <p>Last Action Name : {{ actionName }}</p> -->
      <div>
        <p>{{ error }}</p>
        <p>{{ decodedString }}</p>
        <div class="flex justify-center item-center w-72 lg:w-96 h-96">
          <!-- <video
            ref="video"
            :srcObject="cameraResponse"
            autoplay
            class="w-full overflow-hidden"
          /> -->
          <qrcode-stream
            :camera="camera"
            :track="drawOutline"
            @init="onInit"
            @decode="onDecode"
          >
            <button @click="switchCamera">
              <!-- <img :src="$withBase('/camera-switch.svg')" alt="switch camera"> -->
              Switch
            </button>
            <div
              v-if="loading"
              class="loading-indicator h-full flex items-center justify-center"
            >
              Loading...
            </div>
            <!-- <div class="element">
            </div> -->
            <!-- <div class="scanner-wrapper" v-if="!loading">
              <div class="scanner-overlay">
                <div class="scanner relative flex items-center justify-center w-full h-full">
                  <div class="scanner-outline">
                  </div>
                </div>
              </div>
            </div> -->
          </qrcode-stream>
          <!-- <qrcode-stream @init="onInit" @decode="onDecode" /> -->
        </div>
      </div>
      <!-- <div class="w-full mt-10">
        <button
          class="mt-2 bg-blue-500 px-2 py-2 rounded text-white"
          @click="startCamera"
        >
          Start Camera
        </button>
        <button
          class="mt-2 mx-2 bg-blue-500 px-2 py-2 rounded text-white"
          @click="clickPicture"
        >
          Click Picture
        </button>
        <button
          class="mt-2 mx-2 bg-blue-500 px-2 py-2 rounded text-white"
          @click="switchCamera"
        >
          Switch Camera
        </button>
        <button
          class="mt-2 bg-red-500 px-2 py-2 rounded text-white"
          @click="stopCamera"
        >
          Stop stream
        </button>
      </div> -->
      <p class="w-full text-red-300">
        {{ JSON.stringify(cameraResponse) }}
      </p>
      <!-- <p v-if="noFrontCamera" class="error">
      You don't seem to have a front camera on your device
    </p>

    <p class="error" v-if="noRearCamera">
      You don't seem to have a rear camera on your device
    </p> -->
    </div>
    <!-- <div class="border p-4 grid grid-col-1 md:grid-cols-2">
      <canvas
        ref="canvas"
        class="bg-red-200 w-full hidden"
      />
      <img
        v-for="(image, index) in imgSrc" 
        :key="index"
        :src="image" 
        class="border-2 border-blue-400 m-1"
      >
    </div> -->
  </div>
</template>

<script>
import { QrcodeStream } from "vue3-qrcode-reader";
export default {
  components: {
    QrcodeStream,
  },
  data() {
    return {
      logData: "",
      // actionName: "",
      // cameraResponse: null,
      // capturedImage: null,
      // imgSrc: [],
      // facingMode: "user",

      error: "",
      decodedString: "",
      camera: "auto",
      noRearCamera: false,
      noFrontCamera: false,
      loading: false,
    };
  },
  mounted() {
    this.$store.dispatch("STOP_LOADING");
    try {
      this.logData = "Trying";
    } catch (error) {
      this.logData = error;
    }
  },
  methods: {
    // async startCamera() {
    //   let stream = await navigator.mediaDevices.getUserMedia({
    //     video: {
    //       facingMode: this.facingMode,
    //     },
    //     audio: false,
    //   });
    //   this.$refs.video.srcObject = stream;
    //   this.cameraResponse = stream;
    //   this.actionName = "Start Camera";
    // },
    // clickPicture() {
    //   if (this.cameraResponse.active) {
    //     this.$refs.canvas
    //       .getContext("2d")
    //       .drawImage(
    //         this.$refs.video,
    //         0,
    //         0,
    //         this.$refs.canvas.width,
    //         this.$refs.canvas.height
    //       );
    //     let image_data_url = this.$refs.canvas.toDataURL("image/jpeg");
    //     this.imgSrc.push(image_data_url);
    //     this.actionName = "Click Picture";
    //   }
    // },
    switchCamera() {
      switch (this.camera) {
        case "front":
          this.camera = "rear";
          break;
        case "rear":
          this.camera = "front";
          break;
      }
    },
    // stopCamera() {
    //   for (let i in this.cameraResponse.getTracks()) {
    //     this.cameraResponse.getTracks()[i].stop();
    //   }
    //   this.actionName = "Stop Camera";
    // },
    async onInit(promise) {
      console.log(promise);
      promise
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.log("error", error);
        });
      // show loading indicator
      this.loading = true;

      try {
        const { capabilities } = await promise;
        console.log(capabilities);
        this.error = "";
        // successfully initialized
      } catch (error) {
        console.log(error);
        const triedFrontCamera = this.camera === "front";
        const triedRearCamera = this.camera === "rear";

        const cameraMissingError = error.name === "OverconstrainedError";

        if (triedRearCamera && cameraMissingError) {
          this.noRearCamera = true;
        }

        if (triedFrontCamera && cameraMissingError) {
          this.noFrontCamera = true;
        }
        if (error.name === "NotAllowedError") {
          this.error = "user denied camera access permisson";
        } else if (error.name === "NotFoundError") {
          this.error = "no suitable camera device installed";
        } else if (error.name === "NotSupportedError") {
          this.error = "page is not served over HTTPS (or localhost)";
        } else if (error.name === "NotReadableError") {
          this.error = "maybe camera is already in use";
        } else if (error.name === "OverconstrainedError") {
          // this.error = "did you requested the front camera although there is none?"
          if (triedRearCamera && cameraMissingError) {
            this.error = "You don't seem to have a rear camera on your device";
          }
          if (triedFrontCamera && cameraMissingError) {
            this.error = "You don't seem to have a front camera on your device";
          }
        } else if (error.name === "StreamApiNotSupportedError") {
          this.error = "browser seems to be lacking features";
        }
      } finally {
        // hide loading indicator
        this.loading = false;
      }
    },
    onDecode(decodedString) {
      this.decodedString = decodedString;
      // window.location.replace(decodedString)
    },
    drawOutline(decodeData, context) {
      console.log(decodeData);
      console.log(context);
      var points = [];
      for (var k in decodeData) {
        switch (k) {
          case "topLeftCorner":
            points[0] = decodeData[k];
            break;
          case "topRightCorner":
            points[1] = decodeData[k];
            break;
          case "bottomRightCorner":
            points[2] = decodeData[k];
            break;
          case "bottomLeftCorner":
            points[3] = decodeData[k];
            break;
          default:
            break;
        }
      }
      console.log(points);
      console.log(context);
    },
  },
};
</script>

<style scoped>
button {
  position: absolute;
  left: 10px;
  top: 10px;
}
.loading-indicator {
  font-weight: bold;
  font-size: 2rem;
  text-align: center;
}
.scanner-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}
.scanner-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
}
.scanner-outline {
  position: absolute;
  border: 1px solid red;
  width: 80%;
  height: 80%;
  background-color: none !important;
}
.element {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);

  -webkit-clip-path: polygon(
    0% 0%,
    0% 100%,
    25% 100%,
    25% 25%,
    75% 25%,
    75% 75%,
    25% 75%,
    25% 100%,
    100% 100%,
    100% 0%
  );
  clip-path: polygon(
    0% 0%,
    0% 100%,
    25% 100%,
    25% 25%,
    75% 25%,
    75% 75%,
    25% 75%,
    25% 100%,
    100% 100%,
    100% 0%
  );
}
.element::after {
  content: "";
  position: absolute;
  width: 0.25em;
  height: 90%;
  display: block;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  opacity: 7;
  margin: auto;
  background: rgb(23, 162, 74);
  z-index: 2;
  /* filter: drop-shadow(0 20px greenyellow) drop-shadow(0 60px greenyellow); */
  animation: 4s infinite ease-in-out roll;
}
@keyframes roll {
  0% {
    transform: translateX(-100%);
  }
  50% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}
</style>
